import { useQuery } from "react-query";
import Slider from "react-slick";
import { Row, Col } from "antd";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import LoadingSpinner from "../components/shared/LoadingSpinner";
import Course from "./../components/courses/Course";
import { fetchHomeData } from "../api";
import img11 from "./../assets/img/coding 01.png";
import img22 from "./../assets/img/coding 02.png";
import img33 from "./../assets/img/coding 03.png";
import logo from "./../assets/logo.jpeg";
import { Link } from "react-router-dom";
import "./home.scss";
import { Container } from "react-bootstrap";



const SampleNextArrow = (props) => {
  const { onClick } = props;

  return (
    <button onClick={onClick} className="slider__arrowLeft">
      <FaChevronLeft />
    </button>
  );
}

const SamplePrevArrow = (props) => {
  const { onClick } = props;

  return (
    <button onClick={onClick} className="slider__arrowRight">
      <FaChevronRight />
    </button>
  );
}


const Home = () => {
  const { data, isLoading } = useQuery("home", fetchHomeData);
  const homeData = data?.data;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: homeData?.data?.length < 3 ? homeData?.data?.length : 3,
    // NOTE: make the items per carsoul is 3 if courses is more than or equls 3
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1120,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 989,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <div className="home">
      <Container>
        {/* <div className="row justify-content-around my-4">
          <div className="info col-md-3 mb-3">
            <h2 className="h1 count">{homeData.courses_count}</h2>
            <span className="sepa my-3"></span>
            <p>دورة</p>
          </div>
          <div className="info col-md-3 mb-3">
            <h2 className="h1 count">{homeData.videos_count}</h2>
            <span className="sepa my-3"></span>
            <p>محاضرة</p>
          </div>
          <div className="info col-md-3 mb-3">
            <h2 className="h1 count">{homeData.lecturers_count}</h2>
            <span className="sepa my-3"></span>
            <p>مدرب</p>
          </div>
        </div> */}
        <Row
        >
          {homeData?.abouts?.map((aboutData, index) => (
            <>
              <Col xs={24} md={12}>
                <div className="courses__item">
                  <header className="courses__show__header">
                    <h4>{aboutData.title}</h4>
                    <p className="courses__show__desc">
                      {aboutData.description}
                    </p>
                  </header>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="courses__item">
                  <figure>
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/${aboutData.img}`}
                      alt="angular-course"
                    />
                  </figure>
                </div>
              </Col>
            </>
          ))}
        </Row>

        <section>
          <div className="cards-data text-center py-4">
            <div className="text-white">
              <h1>إسلك مسارك لتعلم لغات البرمجه</h1>
            </div>
            <Row className="my-5">
              <div className="col-lg-4 col-md-6 mt-2  p-2">
                <div className="cards-data__item bg-white rounded">
                  <img className="w-100" src={img11} alt="items" />
                  <h4> استسمر في نفسك</h4>
                  <p>
                    سجل في منصتنا واستفد من العديد من الدورات تؤهلك لإنتاج
                    مشاريع الويب
                  </p>
                  <Link to="/login"> سجل الان &gt;</Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-2 p-2">
                <div className="cards-data__item bg-white rounded">
                  <img className="w-100" src={img22} alt="items" />
                  <h4>
                    انضم الي مجتمع <img src={logo} alt="logo" width="100" />
                  </h4>
                  <p>اختر من الباقات التي تناسب</p>
                  <Link to="/pricing">تصفح الباقات &gt; </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-2  p-2">
                <div className="cards-data__item bg-white rounded">
                  <img className="w-100" src={img33} alt="items" />
                  <h4>استثمر في نفسك</h4>
                  <p>
                    انضم الينا و استفد من العديد من الدورات التي تؤهىك لسوق
                    العمل
                  </p>
                  <Link to="/courses">تصفح الدورات &gt;</Link>
                </div>
              </div>
            </Row>
          </div>
        </section>

        <div className="home__slider py-3">
          <header className="home__slider-header">
            <h2>الكورسات المقترحة</h2>
          </header>
          {!isLoading && (
            <Slider Slider {...settings}>
              {homeData.data?.map(course => (
                <div
                  dir="rtl"
                  className="course-slider__wrapper text-align-start"
                  key={course.id}
                >
                  <Course course={course} />
                </div>
              ))}
            </Slider>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Home;
