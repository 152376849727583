import { useState, useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { Button, Form, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setIsLogedIn, setToken, setUser } from "../../redux";
import { HAS_REGISTER, AUTH_TOKEN, CURRENT_USER } from "./../../helper";
import { openNotification } from "../shared/Notification";
import "./registeration.scss";
import { loginApi, loginWithFacebook, loginWithGoogle } from "./../../api";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remmemberMe, setRemmemberMe] = useState(false);
  const message = useLocation().state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginFormRef = useRef();

  useEffect(() => {
    if (message === HAS_REGISTER) {
      openNotification({
        message: "تم انشاء حساب",
        description: "برجاء ادخال البريد الالكترونى وكلمة المرور من اجل الدخول",
        isNotError: true,
      });
    }
  }, [message]);

  const authAction = (user, token) => {
    dispatch(setUser(user));
    dispatch(setToken(token));
    dispatch(setIsLogedIn(true));
  };

  const setLocalstorage = (currentUser, token) => {
    localStorage.setItem(AUTH_TOKEN, token);
    localStorage.setItem(CURRENT_USER, JSON.stringify(currentUser));
    authAction(currentUser, token);
  };

  // const setSession = (currentUser, token) => {
  //   sessionStorage.setItem(AUTH_TOKEN, token);
  //   sessionStorage.setItem(CURRENT_USER, JSON.stringify(currentUser));
  //   authAction(currentUser, token);
  // };

  const logInWithEmailAndPassword = async accessToken => {
    setLocalstorage(null, accessToken);
    // if (remmemberMe) {
    //   setLocalstorage(null, accessToken);
    // } else {
    //   setSession(null, accessToken);
    // }
    navigate("/");
  };

  const { mutate, isLoading } = useMutation(loginApi, {
    onSuccess: ({ data }) => logInWithEmailAndPassword(data.access_token),
    onError: () => {
      openNotification({
        title: "الحساب او كلمة المرور خطأ",
        message: "برجاء ادخال البريد الالكترونى وكلمة المرور صحيحين",
      });
    },
  });

  const { mutate: mutateFacebook } = useMutation(loginWithFacebook, {
    onSuccess: ({ data }) => logInWithEmailAndPassword(data.access_token),
    onError: () => {
      openNotification({
        title: "الحساب خطأ",
        message: "برجاء التأكد من حساب الفيس بوك",
      });
    },
  });
  const { mutate: mutateGoogle } = useMutation(loginWithGoogle, {
    onSuccess: ({ data }) => logInWithEmailAndPassword(data.access_token),
    onError: () => {
      openNotification({
        title: "الحساب خطأ",
        message: "برجاء التأكد من حساب جوجل",
      });
    },
  });

  return (
    <div className="container">
      <section className="login-page row">
        <Container className="col-md-9 col-lg-5 mx-auto mt-4">
          <Form ref={loginFormRef}>
            <h2 className="login-title py-4">تسجيل الدخول</h2>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>الايميل</Form.Label>
              <Form.Control
                type="email"
                placeholder="الايميل"
                required
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>كلمة المرور</Form.Label>
              <Form.Control
                type="password"
                placeholder="كلمة المرور"
                required
                value={password}
                onChange={e => {
                  setPassword(e.target.value);
                }}
              />
            </Form.Group>

            <div className="check-container">
              <Form.Group className="d-flex">
                <Form.Check
                  type="checkbox"
                  id="remember"
                  value={remmemberMe}
                  onChange={e => setRemmemberMe(e.target.value)}
                />
                <Form.Label className="px-2" htmlFor="remember">
                  تذكرني
                </Form.Label>
              </Form.Group>
              <Link to="/forget-password" className="forget">
                <button
                  className="resset_password_button"
                  type="button"
                  disabled={!email}
                >
                  هل نسيت كلمة المرور؟
                </button>
              </Link>
            </div>

            <div className="w-100">
              <Form.Group className="button mt-3 mb-2">
                <Button
                  disabled={isLoading || !email || !password}
                  className="w-100 shadow-none"
                  onClick={() =>
                    mutate({
                      email,
                      password,
                    })
                  }
                >
                  {isLoading ? "جارى التحميل" : "تسجيل الدخول"}
                </Button>
              </Form.Group>
            </div>
          </Form>
          <Form>
            <Form.Group className="google pb-2">
              <LoginSocialGoogle
                isOnlyGetToken={true}
                client_id={process.env.REACT_APP_GG_APP_ID || ""}
                onResolve={({ data }) =>
                  mutateGoogle({ token: data.access_token })
                }
              >
                <Button
                  variant="danger"
                  className="w-100 shadow-none"
                  type="button"
                >
                  تسجيل بحساب Google
                </Button>
              </LoginSocialGoogle>
            </Form.Group>
          </Form>
          <Form className="pb-2">
            <Form.Group className="facebook pb-3">
              <LoginSocialFacebook
                isOnlyGetToken={true}
                appId={process.env.REACT_APP_FB_APP_ID || ""}
                onResolve={({ data }) =>
                  mutateFacebook({ token: data.accessToken })
                }
              >
                <Button
                  variant="primary"
                  className="w-100 shadow-none"
                  type="button"
                >
                  تسجيل بحساب Facebook
                </Button>
              </LoginSocialFacebook>
            </Form.Group>

            <p className="text-center my-3">
              ليس لديك حساب؟ <Link to="/register">إنشاء حساب جديد</Link>
            </p>
          </Form>
        </Container>
      </section>
    </div>
  );
};

export default Login;
