import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./views/LoginPage";
import RegisterPage from "./views/RegisterPage";

import PricingPage from "./views/PricingPage";
import Home from "./views/Home";
import Header from "./components/shared/Header";
import CoursesPage from "./views/CoursesPage";
import Profile from "./components/instructor/Profile";
import MyLearningPage from "./views/MyLearningPage";
import CoursesLessons from "./components/Courses-Lessons/CoursesLesson";
import StudentSetting from "./components/Student/StudentSettings";
import ForgetPassword from "./components/registeration/ForgetPassword";
import CourseDetails from "./components/courses/CourseDetails";
import Footer from "./components/shared/Footer";

// styling
import "antd/dist/antd.min.css";
import "./App.scss";
import Questions from "./components/Questions/Questions";
import PrivacyPolicy from "./components/Privacy/PrivacyPolicy";

import { setIsLogedIn, setToken } from "./redux";
import {
  AUTH_TOKEN,
  getSessionStorageData,
  getLocalStoredData,
} from "./helper";
import NonAuthRoute from "./components/shared/NonAuthRoute";
import AuthRoute from "./components/shared/AuthRoute";
import ResetPassword from "./components/registeration/ResetPassword";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (getSessionStorageData(AUTH_TOKEN)) {
      dispatch(setToken(getSessionStorageData(AUTH_TOKEN)));
      dispatch(setIsLogedIn(true));
    } else if (getLocalStoredData(AUTH_TOKEN)) {
      dispatch(setToken(getLocalStoredData(AUTH_TOKEN)));
      dispatch(setIsLogedIn(true));
    }
  }, [dispatch]);

  return (
    <>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/courses" element={<CoursesPage />} />
          <Route path="/courses/:id" element={<CourseDetails />} />
          <Route path="/faq" element={<Questions />} />
          <Route path="/terms" element={<PrivacyPolicy />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/lecturer/:id" element={<Profile />} />

          <Route
            path="/my-learning"
            element={
              <NonAuthRoute>
                <MyLearningPage />
              </NonAuthRoute>
            }
          ></Route>

          <Route
            path="/student-settings"
            element={
              <NonAuthRoute>
                <StudentSetting />
              </NonAuthRoute>
            }
          ></Route>

          <Route
            path="/course-lessons/:id"
            element={
              <NonAuthRoute>
                <CoursesLessons />
              </NonAuthRoute>
            }
          ></Route>

          <Route
            path="/login"
            element={
              <AuthRoute>
                <LoginPage />
              </AuthRoute>
            }
          ></Route>

          <Route
            path="/login/google/*"
            element={
              <AuthRoute>
                <Home />
              </AuthRoute>
            }
          ></Route>

          <Route
            path="/register"
            element={
              <AuthRoute>
                <RegisterPage />
              </AuthRoute>
            }
          ></Route>

          <Route
            path="/reset-password"
            element={
              <AuthRoute>
                <ResetPassword />
              </AuthRoute>
            }
          ></Route>
          <Route
            path="/forget-password"
            element={
              <AuthRoute>
                <ForgetPassword />
              </AuthRoute>
            }
          ></Route>
        </Routes>
      </main>
      <Footer />
    </>
  );
}

export default App;
