import { Button, Form } from "react-bootstrap";
import "./registeration.scss";
import { useState, useRef } from "react";
import { openNotification } from "../shared/Notification";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { fetchResetPasswordLink } from "../../api/pages/forget-password";

const ForgetPassword = () => {
  const formRef = useRef();
  const resettingUrlInputRef = useRef();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const { mutate } = useMutation(fetchResetPasswordLink, {
    onSuccess: data => {
      resettingUrlInputRef.current.value = data?.data?.reset_link;
      emailjs
        .sendForm(
          "service_2kslb2v",
          "template_vhe2ou4",
          formRef.current,
          "bEC_M3cldUQT7cAFb"
        )
        .then(res => {
          if (res.status === 200) {
            openNotification({
              message: "تغيير كلمة المرور",
              description:
                "تم ارسال لينك اعادة تعيين كلمة السر الى البريد الالكتروني ",
              isNotError: true,
            });
            navigate("/login");
          }
        });
    },
    onError: () => {
      openNotification({
        title: "البريد الالكتروني غير صحيح",
        message: "برجاء التاكد من البيانات المدخلة",
      });
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    mutate({ email });
  };

  return (
    <div className="container">
      <section className="login-page row">
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          className="col-md-8 col-lg-4 mx-auto mt-4 pb-2 px-4"
        >
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>البريد الالكتروني</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="البريد الالكتروني"
              required
              onChange={e => {
                setEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group
            className="mb-3 d-none"
            controlId="formBasicResetPassword"
          >
            <Form.Control
              ref={resettingUrlInputRef}
              name="resetting_password_link"
              type="url"
            />
          </Form.Group>
          <Form.Group className="button py-3">
            <Button className="w-100" disabled={!email} type="submit">
              إعادة تعيين كلمة المرور
            </Button>
          </Form.Group>
        </Form>
      </section>
    </div>
  );
};

export default ForgetPassword;
