import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import LoadingSpinner from "../shared/LoadingSpinner";
import { fetchLecturer } from "../../api";
import "./profile.scss";

const Profile = () => {
  const { id } = useParams();
  const { data, isLoading } = useQuery(["lecturer", id], () =>
    fetchLecturer(id)
  );

  const lecturer = data?.data?.data;

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <div className="instructor-profile mt-5 pt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 d-flex flex-column align-items-center justify-content-center">
            <div className="img-container">
              {lecturer.photo ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/${lecturer.photo}`}
                  alt={lecturer.name}
                />
              ) : (
                // TODO: replace with elegant loading image
                <div className="spinner-grow" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
            <div className="social-container mt-4 fs-3">
              <a href={lecturer.linkedin_link} className="mx-1">
                <i className="fa-brands fa-linkedin"></i>
              </a>
              <a href={lecturer.twitter_link} className="mx-1">
                <i className="fa-brands fa-twitter-square"></i>
              </a>
              <a href={lecturer.github_link} className="mx-1">
                <i className="fa-brands fa-github"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-8">
            <h2 className="h1 mb-4">{lecturer.name}</h2>
            <div>
              <p className="mb-4">{lecturer.position} </p>
              <p className="mb-4">{lecturer.bio}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
